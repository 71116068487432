import { combineReducers } from "redux";
import closeForm from "./closeForm";
import colapseCartItems from "./colapseCartItems";
import selectedVehicle from "./selectvehicle";
import displayShippingForm from "./displayShippingForm";
import newCart from "./cart";
import modifCartBtn from "./modifCartBtn";
import setDisplayStates from "./displayStates";

const rootReducer = combineReducers({
  closeForm,
  colapseCartItems,
  selectedVehicle,
  displayShippingForm,
  newCart,
  modifCartBtn,
  setDisplayStates,
});

export default rootReducer;
