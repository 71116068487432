import React, { useState } from "react";
import * as StyledComponents from "./searchSKU.module.scss";

// Import querry string library
var qs = require("qs");

export const SearchSku = () => {
  const [SKU, setSKU] = useState("");

  //get the sku data
  async function getSKU(params) {
    let notfound = true;
    //remove all white spaces
    let querryString = params.replace(/ /g, "");
    querryString = querryString.replace(" ", "%2B");
    querryString = querryString.toUpperCase();
    const query = qs.stringify({
      _where: { sku: querryString },
    });
    await fetch(`https://www.wheels-api.com/wheels?${query}`)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result[0] !== undefined) {
            document.location.href = "/wheel?sku=" + querryString;
            notfound = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    await fetch(`https://www.wheels-api.com/tires?${query}`)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result[0] !== undefined) {
            document.location.href = "/tire?sku=" + querryString;
            notfound = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );

    if (notfound === true) {
      console.log("Not found");
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    getSKU(SKU);
  };
  return (
    <>
      <form
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <input
          type="text"
          value={SKU}
          onChange={(e) => setSKU(e.target.value)}
          placeholder="SKU"
          className={StyledComponents.input}
        />
        <button className={StyledComponents.button} type="submit">
          Rechercher
        </button>
      </form>
    </>
  );
};
