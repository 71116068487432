import React, { useState } from "react";
import { SearchSku } from "../searchSKU/searchSKU";
import Cart from "../cart/cart/cart";
import Container from "../container/container";
import { Link } from "gatsby";
import menu from "../../icons/menu.svg";
import close from "../../icons/close.svg";
import * as StyledComponents from "./navbar.module.scss";

import { PromoCodeNavBar } from "./promoCode/promocode";

export const NavBar = () => {
  const [DisplayMenu, setDisplayMenu] = useState();
  const [DisplayMenubtn, setDisplayMenubtn] = useState();
  const [DisplayClosebtn, setDisplayClosebtn] = useState();

  //display the mobile menu
  function openMenu() {
    setDisplayMenu({ display: "flex" });
    setDisplayMenubtn({ display: "none" });
    setDisplayClosebtn({ display: "inline" });
  }

  //display the menu button
  function closeMenu() {
    setDisplayMenu({ display: "none" });
    setDisplayMenubtn({ display: "inline" });
    setDisplayClosebtn({ display: "none" });
  }

  return (
    <nav className={StyledComponents.nav}>
      <PromoCodeNavBar />
      <Container>
        <div className={StyledComponents.fullmenu}>
          <div className={StyledComponents.menubtnContainer}>
            <button
              style={DisplayMenubtn}
              className={StyledComponents.menubtn}
              onClick={() => openMenu()}
            >
              <img src={menu} alt="menu" />
            </button>
            <button
              style={DisplayClosebtn}
              className={StyledComponents.closebtn}
              onClick={() => closeMenu()}
            >
              <img src={close} alt="menu" />
            </button>
          </div>

          <ul className={StyledComponents.links} style={DisplayMenu}>
            <li>
              <Link to="/tires">PNEUS</Link>
            </li>
            <li>
              <Link to="/wheels">JANTES / MAGS</Link>
            </li>
            <li>
              <Link to="/contact">CONTACT</Link>
            </li>
            <li className={StyledComponents.mobsku}>
              <SearchSku />
            </li>
          </ul>
          <div className={StyledComponents.title}>
            <Link to="/">
              <h1>CANADA OFFROAD</h1>
            </Link>
          </div>
          <ul>
            <li className={StyledComponents.dksku}>
              <SearchSku />
            </li>
            <li>
              <Cart />
            </li>
            {/*
          <li>English</li>
          */}
          </ul>
        </div>
      </Container>
    </nav>
  );
};
