const openShippingForm = () => {
  return {
    type: "OPENSF",
  };
};

const closeShippingForm = () => {
  return {
    type: "CLOSESF",
  };
};

export default {
  openShippingForm,
  closeShippingForm,
};
