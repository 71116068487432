import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateMVehicle } from "../cartFunctions";
import * as StyledComponets from "./selectedvehicle.module.scss";

import { displayShippingBlock } from "../../setDisplayStates/functions";

//redux
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";

// markup
export const SelectedVehicleComponent = () => {
  //redux
  const SelectedVehicle = useSelector((state) => state.selectedVehicle);
  const dispatch = useDispatch();

  //YUP shema
  const schema = yup
    .object({
      syear: yup.string().required(),
      smake: yup.string().required(),
      smodel: yup.string().required(),
      ssubmodel: yup.string().required(),
    })
    .required();

  // resolver and register form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //submit form
  const onSubmit = (data) => {
    let sv = (
      data.smake +
      " " +
      data.syear +
      " " +
      data.smodel +
      " " +
      data.ssubmodel
    ).toUpperCase();

    //update local storage vehicle
    updateMVehicle(JSON.stringify({ vehicle: sv }));

    //insert into SV
    dispatch(allActions.selectVehicle.setSelectedVehicle({ vehicle: sv }));

    //display shipping block
    let newDisplayStates = displayShippingBlock();
    dispatch(allActions.setDisplayStates.setDisplayStates(newDisplayStates));
  };

  //remove the selected vehicle
  const removeSelection = (e) => {
    e.preventDefault();

    //remove ls selected vehcile
    updateMVehicle(JSON.stringify({ vehicle: "" }));

    //insert into SV
    dispatch(allActions.selectVehicle.setSelectedVehicle({ vehicle: "" }));
  };

  //form
  const vehicleForm = (
    <div className={StyledComponets.selectVehicleForm}>
      <h4 className={StyledComponets.selTitle}>
        ⚠ Veuillez rentrer votre véhicule <br /> à fin de continuer avec le
        paiement
      </h4>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={StyledComponets.selectCar}
      >
        <label htmlFor="Année">Année :</label>
        <input {...register("syear")} placeholder="2010" />
        <p className={StyledComponets.error}>
          {errors.syear?.type === "required" &&
            "⚠ Veuillez rentrer votre année"}
        </p>

        <label htmlFor="Marque">Marque : </label>
        <input {...register("smake")} placeholder="Ford" />
        <p className={StyledComponets.error}>
          {errors.smake?.type === "required" &&
            "⚠ Veuillez rentrer votre marque"}
        </p>
        <label htmlFor="Modèle">Modèle : </label>
        <input {...register("smodel")} placeholder="F-150" />
        <p className={StyledComponets.error}>
          {errors.smodel?.type === "required" &&
            "⚠ Veuillez rentrer votre modèle"}
        </p>
        <label htmlFor="Sous-modèle">Sous-modèle : </label>
        <input {...register("ssubmodel")} placeholder="XLT" />
        <p className={StyledComponets.error}>
          {errors.ssubmodel?.type === "required" &&
            "⚠ Veuillez rentrer votre sous-modèle"}
        </p>
        <button variant="outlined" type="submit">
          cliquez ici pour continuer &#10148;
        </button>
      </form>
    </div>
  );

  //display the selected vehicle
  const selecVeh = () => (
    <>
      <div className={StyledComponets.selectedCar}>
        <h4>Véhicule sélectionne :</h4>
        <p className={StyledComponets.chosen}>{SelectedVehicle.vehicle}</p>
        <form onSubmit={(e) => removeSelection(e)}>
          <button type="submit">Changer de vehicule</button>
        </form>
      </div>
    </>
  );

  //display the form or the selection
  function selectedVehicle() {
    if (SelectedVehicle.vehicle !== "") {
      return selecVeh();
    } else {
      return vehicleForm;
    }
  }

  return <>{selectedVehicle()}</>;
};
