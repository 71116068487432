import React, { useState } from "react";
import { CartContext } from "./cartContext";

// markup
const CartGlobalContext = ({ children }) => {
  //create an object
  let initValue = [
    {
      totalItems: 0,
      totalAmount: 0,
      discount: 0,
      totalWheels: 0,
      totalTires: 0,
      totalTPMS: 0,
      TotalNutSets: 0,
    },
  ];
  //change to string
  initValue = JSON.stringify(initValue);
  //set initial value
  const [value, setValue] = useState(initValue);
  return (
    <>
      <CartContext.Provider value={[value, setValue]}>
        {children}
      </CartContext.Provider>
    </>
  );
};

export default CartGlobalContext;
