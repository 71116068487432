const modifCartBtn = (state = {}, action) => {
  switch (action.type) {
    case "OPEN_MCB":
      return {
        ...state,
        display: "inline",
      };
    case "CLOSE_MCB":
      return {
        ...state,
        display: "none",
      };
    default:
      return state;
  }
};

export default modifCartBtn;
