export function NoVehicleSelected() {
  let newCartValue = {
    cartItems: { display: "inline" },
    changeCartBtn: { display: "none" },
    shippingFormBlock: { display: "inline" },
    insertAdressbtn: { display: "none" },
    shippingForm: { display: "none" },
    changeShippingBtn: { display: "none" },
    cc: { display: "none" },
    vehicleSelected: false,
  };

  return newCartValue;
}

export function VehicleSelected() {
  let newCartValue = {
    cartItems: { display: "inline" },
    changeCartBtn: { display: "none" },
    shippingFormBlock: { display: "inline" },
    insertAdressbtn: { display: "inline" },
    shippingForm: { display: "none" },
    changeShippingBtn: { display: "none" },
    cc: { display: "none" },
    vehicleSelected: true,
  };

  return newCartValue;
}

export function displayShippingBlock() {
  let newCartValue = {
    cartItems: { display: "inline" },
    changeCartBtn: { display: "none" },
    shippingFormBlock: { display: "inline" },
    insertAdressbtn: { display: "inline" },
    shippingForm: { display: "none" },
    changeShippingBtn: { display: "none" },
    cc: { display: "none" },
    vehicleSelected: true,
  };

  return newCartValue;
}

export function hideShippingBlock() {
  let newCartValue = {
    cartItems: { display: "inline" },
    changeCartBtn: { display: "none" },
    shippingFormBlock: { display: "none" },
    insertAdressbtn: { display: "none" },
    shippingForm: { display: "none" },
    changeShippingBtn: { display: "none" },
    cc: { display: "none" },
    vehicleSelected: false,
  };

  return newCartValue;
}

export function insertAdress() {
  let newCartValue = {
    cartItems: { display: "none" },
    changeCartBtn: { display: "inline" },
    shippingFormBlock: { display: "inline" },
    insertAdressbtn: { display: "none" },
    shippingForm: { display: "inline" },
    changeShippingBtn: { display: "none" },
    cc: { display: "none" },
    vehicleSelected: true,
  };

  return newCartValue;
}

export function modifyCartBtn() {
  let newCartValue = {
    cartItems: { display: "inline" },
    changeCartBtn: { display: "none" },
    shippingFormBlock: { display: "inline" },
    insertAdressbtn: { display: "inline" },
    shippingForm: { display: "none" },
    changeShippingBtn: { display: "none" },
    cc: { display: "none" },
    vehicleSelected: true,
  };

  return newCartValue;
}

export function enterCC() {
  let newCartValue = {
    cartItems: { display: "none" },
    changeCartBtn: { display: "inline" },
    shippingFormBlock: { display: "inline" },
    insertAdressbtn: { display: "none" },
    shippingForm: { display: "none" },
    changeShippingBtn: { display: "inline" },
    cc: { display: "inline" },
    vehicleSelected: true,
  };

  return newCartValue;
}

export function modifyShippingBtn() {
  let newCartValue = {
    cartItems: { display: "none" },
    changeCartBtn: { display: "inline" },
    shippingFormBlock: { display: "inline" },
    insertAdressbtn: { display: "none" },
    shippingForm: { display: "inline" },
    changeShippingBtn: { display: "none" },
    cc: { display: "none" },
    vehicleSelected: true,
  };

  return newCartValue;
}
