//get and update Version
export function getVersion(givenVersion) {
  if (localStorage.getItem("COversion") !== null) {
    let x = localStorage.getItem("COversion");
    if (x !== givenVersion) {
      localStorage.clear();
      localStorage.setItem("COversion", givenVersion);
    }
  } else {
    localStorage.clear();
    localStorage.setItem("COversion", givenVersion);
  }
}

//get LocalStorage Vehicle
export function getVehicle() {
  if (localStorage.getItem("COvehicle") !== null) {
    let x = localStorage.getItem("COvehicle");
    return JSON.parse(x);
  } else {
    return [];
  }
}

//update LocalStorage manual selected vehicle
export function updateMVehicle(newSelectedVehicle) {
  localStorage.setItem("COmvehicle", newSelectedVehicle);
}

//get LocalStorage manual selected Vehicle
export function getMVehicle() {
  if (localStorage.getItem("COmvehicle") !== null) {
    let x = localStorage.getItem("COmvehicle");
    return JSON.parse(x);
  }
}

//get LocalStorage Cart
export function getCart() {
  if (localStorage.getItem("COcart") !== null) {
    let x = localStorage.getItem("COcart");
    return JSON.parse(x);
  }
}

//Update LocalStorage Cart
function updateLocalStorageCart(newLocalStorageCart) {
  //Set Local Storage Cart as string
  localStorage.setItem("COcart", newLocalStorageCart);
}

/*------------------------*/

//Add wheel to Cart
export function addWheelToCart(CartValue, wheelToAdd) {
  //create all skus array
  let allSkus = [];
  //get the array of all skus on the cart
  CartValue.wheels.map((item) => {
    allSkus.push(item.sku);
  });

  //inser product if sku dont exist
  if (allSkus.indexOf(wheelToAdd.sku) === -1) {
    CartValue.wheels.push(wheelToAdd);
  } else {
    //change number of items if exists
    let index = allSkus.indexOf(wheelToAdd.sku);
    CartValue.wheels[index].total += wheelToAdd.total;
  }

  //new cart values
  let wheels = CartValue.wheels;
  let nuts = { qty: CartValue.nuts.qty + 1 };
  let tpms = { qty: CartValue.tpms.qty + wheelToAdd.total };
  let totalItems = { qty: CartValue.totalItems.qty + wheelToAdd.total };

  let newCartValue = {
    wheels: wheels,
    tires: CartValue.tires,
    tpms: tpms,
    nuts: nuts,
    totalTires: CartValue.totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
  };

  //Calculate subtotal and discount
  let std = calculateSubTotal(newCartValue);

  //new cart values from subtotal and discount
  let discountAmount = std.discount;
  let subTotal = std.subtotal;

  //calculate ttt
  let tpstvqt = calculateTotal(subTotal - discountAmount);
  //new values from ttt
  let tps = tpstvqt.tps;
  let tvq = tpstvqt.tvq;
  let total = tpstvqt.total;

  //new cart value after $$$
  newCartValue = {
    wheels: wheels,
    tires: CartValue.tires,
    tpms: tpms,
    nuts: nuts,
    totalTires: CartValue.totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
    discountAmount: discountAmount,
    subTotal: subTotal,
    tps: tps,
    tvq: tvq,
    total: total,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

//Add tire to Cart
export function addTireToCart(CartValue, tireToAdd) {
  //create all skus array
  let allSkus = [];
  //get the array of all skus on the cart
  CartValue.tires.map((item) => {
    allSkus.push(item.sku);
  });

  //inser product if sku dont exist
  if (allSkus.indexOf(tireToAdd.sku) === -1) {
    CartValue.tires.push(tireToAdd);
  } else {
    //change number of items if exists
    let index = allSkus.indexOf(tireToAdd.sku);
    CartValue.tires[index].total += tireToAdd.total;
  }

  //new cart values
  let tires = CartValue.tires;
  let totalItems = { qty: CartValue.totalItems.qty + tireToAdd.total };
  let totalTires = CartValue.totalTires + tireToAdd.total;

  let newCartValue = {
    wheels: CartValue.wheels,
    tires: tires,
    tpms: CartValue.tpms,
    nuts: CartValue.nuts,
    totalTires: totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
    discountAmount: CartValue.discountAmount,
  };

  //Calculate subtotal and discount
  let std = calculateSubTotal(newCartValue);
  //new cart values from subtotal and discount
  let discountAmount = std.discount;
  let subTotal = std.subtotal;
  //calculate ttt
  let tpstvqt = calculateTotal(subTotal - discountAmount);
  //new values from ttt
  let tps = tpstvqt.tps;
  let tvq = tpstvqt.tvq;
  let total = tpstvqt.total;

  //new cart value after $$$
  newCartValue = {
    wheels: CartValue.wheels,
    tires: tires,
    tpms: CartValue.tpms,
    nuts: CartValue.nuts,
    totalTires: totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
    discountAmount: discountAmount,
    subTotal: subTotal,
    tps: tps,
    tvq: tvq,
    total: total,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

//Remove wheel from the Cart by sku
export function removeWheel(CartValue, skuToRemove, totalToRemove) {
  //create all skus array
  let allSkus = [];
  //get the array of all skus on the cart
  CartValue.wheels.map((item) => {
    allSkus.push(item.sku);
  });

  //get index of sku to remove
  let index = allSkus.indexOf(skuToRemove);
  CartValue.wheels.splice([index], 1);

  //new cart values
  let wheels = CartValue.wheels;
  let totalItems = { qty: CartValue.totalItems.qty - totalToRemove };

  let newCartValue = {
    wheels: wheels,
    tires: CartValue.tires,
    tpms: CartValue.tpms,
    nuts: CartValue.nuts,
    totalTires: CartValue.totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
    discountAmount: CartValue.discountAmount,
  };

  //Calculate subtotal and discount
  let std = calculateSubTotal(newCartValue);
  //new cart values from subtotal and discount
  let discountAmount = std.discount;
  let subTotal = std.subtotal;
  //calculate ttt
  let tpstvqt = calculateTotal(subTotal - discountAmount);
  //new values from ttt
  let tps = tpstvqt.tps;
  let tvq = tpstvqt.tvq;
  let total = tpstvqt.total;

  //new cart value after $$$
  newCartValue = {
    wheels: wheels,
    tires: CartValue.tires,
    tpms: CartValue.tpms,
    nuts: CartValue.nuts,
    totalTires: CartValue.totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
    discountAmount: discountAmount,
    subTotal: subTotal,
    tps: tps,
    tvq: tvq,
    total: total,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

//Remove Tire from the Cart by sku
export function removeTire(CartValue, skuToRemove, totalToRemove) {
  //create all skus array
  let allSkus = [];
  //get the array of all skus on the cart
  CartValue.tires.map((item) => {
    allSkus.push(item.sku);
  });

  //get index of sku to remove
  let index = allSkus.indexOf(skuToRemove);
  CartValue.tires.splice([index], 1);

  //new cart values
  let tires = CartValue.tires;
  let totalTires = CartValue.totalTires - totalToRemove;
  let totalItems = { qty: CartValue.totalItems.qty - totalToRemove };

  let newCartValue = {
    wheels: CartValue.wheels,
    tires: tires,
    tpms: CartValue.tpms,
    nuts: CartValue.nuts,
    totalTires: totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
    discountAmount: CartValue.discountAmount,
  };

  //Calculate subtotal and discount
  let std = calculateSubTotal(newCartValue);
  //new cart values from subtotal and discount
  let discountAmount = std.discount;
  let subTotal = std.subtotal;
  //calculate ttt
  let tpstvqt = calculateTotal(subTotal - discountAmount);
  //new values from ttt
  let tps = tpstvqt.tps;
  let tvq = tpstvqt.tvq;
  let total = tpstvqt.total;

  //new cart value after $$$
  newCartValue = {
    wheels: CartValue.wheels,
    tires: tires,
    tpms: CartValue.tpms,
    nuts: CartValue.nuts,
    totalTires: totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
    discountAmount: discountAmount,
    subTotal: subTotal,
    tps: tps,
    tvq: tvq,
    total: total,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

//Remove all Nuts from the cart
export function removeNuts(CartValue) {
  let newCartValue = {
    wheels: CartValue.wheels,
    tires: CartValue.tires,
    tpms: CartValue.tpms,
    nuts: { qty: 0 },
    totalTires: CartValue.totalTires,
    totalItems: CartValue.totalItems,
    discount: CartValue.discount,
    discountAmount: CartValue.discountAmount,
  };

  //Calculate subtotal and discount
  let std = calculateSubTotal(newCartValue);
  //new cart values from subtotal and discount
  let discountAmount = std.discount;
  let subTotal = std.subtotal;
  //calculate ttt
  let tpstvqt = calculateTotal(subTotal - discountAmount);
  //new values from ttt
  let tps = tpstvqt.tps;
  let tvq = tpstvqt.tvq;
  let total = tpstvqt.total;

  //new cart value after $$$
  newCartValue = {
    wheels: CartValue.wheels,
    tires: CartValue.tires,
    tpms: CartValue.tpms,
    nuts: { qty: 0 },
    totalTires: CartValue.totalTires,
    totalItems: CartValue.totalItems,
    discount: CartValue.discount,
    discountAmount: discountAmount,
    subTotal: subTotal,
    tps: tps,
    tvq: tvq,
    total: total,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

//Remove all sensors from the cart
export function removeSensors(CartValue) {
  let newCartValue = {
    wheels: CartValue.wheels,
    tires: CartValue.tires,
    tpms: { qty: 0 },
    nuts: CartValue.nuts,
    totalTires: CartValue.totalTires,
    totalItems: CartValue.totalItems,
    discount: CartValue.discount,
    discountAmount: CartValue.discountAmount,
  };

  //Calculate subtotal and discount
  let std = calculateSubTotal(newCartValue);
  //new cart values from subtotal and discount
  let discountAmount = std.discount;
  let subTotal = std.subtotal;
  //calculate ttt
  let tpstvqt = calculateTotal(subTotal - discountAmount);
  //new values from ttt
  let tps = tpstvqt.tps;
  let tvq = tpstvqt.tvq;
  let total = tpstvqt.total;

  //new cart value after $$$
  newCartValue = {
    wheels: CartValue.wheels,
    tires: CartValue.tires,
    tpms: { qty: 0 },
    nuts: CartValue.nuts,
    totalTires: CartValue.totalTires,
    totalItems: CartValue.totalItems,
    discount: CartValue.discount,
    discountAmount: discountAmount,
    subTotal: subTotal,
    tps: tps,
    tvq: tvq,
    total: total,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

//Change wheel qty on cart
export function changeQtyWheel(CartValue, skuToChange, newTotal) {
  //create all skus array
  let allSkus = [];
  //get the array of all skus on the cart
  CartValue.wheels.map((item) => {
    allSkus.push(item.sku);
  });

  //change total value of item
  //get the index of the sku to change
  let index = allSkus.indexOf(skuToChange);
  //old total
  let oldTotal = CartValue.wheels[index].total;
  //change new total
  CartValue.wheels[index].total = newTotal;

  //new cart values
  let wheels = CartValue.wheels;
  let nuts = CartValue.nuts;
  let tpms = CartValue.tpms;
  let totalItems = { qty: CartValue.totalItems.qty + (newTotal - oldTotal) };

  let newCartValue = {
    wheels: wheels,
    tires: CartValue.tires,
    tpms: tpms,
    nuts: nuts,
    totalTires: CartValue.totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
    discountAmount: CartValue.discountAmount,
  };

  //Calculate subtotal and discount
  let std = calculateSubTotal(newCartValue);
  //new cart values from subtotal and discount
  let discountAmount = std.discount;
  let subTotal = std.subtotal;
  //calculate ttt
  let tpstvqt = calculateTotal(subTotal - discountAmount);
  //new values from ttt
  let tps = tpstvqt.tps;
  let tvq = tpstvqt.tvq;
  let total = tpstvqt.total;

  //new cart value after $$$
  newCartValue = {
    wheels: wheels,
    tires: CartValue.tires,
    tpms: tpms,
    nuts: nuts,
    totalTires: CartValue.totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
    discountAmount: discountAmount,
    subTotal: subTotal,
    tps: tps,
    tvq: tvq,
    total: total,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

//Change tire qty on cart
export function changeQtyTire(CartValue, skuToChange, newTotal) {
  //create all skus array
  let allSkus = [];
  //get the array of all skus on the cart
  CartValue.tires.map((item) => {
    allSkus.push(item.sku);
  });

  //change total value of item
  //get the index of the sku to change
  let index = allSkus.indexOf(skuToChange);
  //old total
  let oldTotal = CartValue.tires[index].total;
  //change new total
  CartValue.tires[index].total = newTotal;

  //new cart values
  let tires = CartValue.tires;
  let totalTires = CartValue.totalTires + (newTotal - oldTotal);
  let totalItems = { qty: CartValue.totalItems.qty + (newTotal - oldTotal) };

  let newCartValue = {
    wheels: CartValue.wheels,
    tires: tires,
    tpms: CartValue.tpms,
    nuts: CartValue.nuts,
    totalTires: totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
    discountAmount: CartValue.discountAmount,
  };

  //Calculate subtotal and discount
  let std = calculateSubTotal(newCartValue);
  //new cart values from subtotal and discount
  let discountAmount = std.discount;
  let subTotal = std.subtotal;
  //calculate ttt
  let tpstvqt = calculateTotal(subTotal - discountAmount);
  //new values from ttt
  let tps = tpstvqt.tps;
  let tvq = tpstvqt.tvq;
  let total = tpstvqt.total;

  //new cart value after $$$
  newCartValue = {
    wheels: CartValue.wheels,
    tires: tires,
    tpms: CartValue.tpms,
    nuts: CartValue.nuts,
    totalTires: totalTires,
    totalItems: totalItems,
    discount: CartValue.discount,
    discountAmount: discountAmount,
    subTotal: subTotal,
    tps: tps,
    tvq: tvq,
    total: total,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

//Change TPMS qty on cart
export function changeQtyTPMS(CartValue, newTotal) {
  //new cart values
  let tpms = { qty: newTotal };

  let newCartValue = {
    wheels: CartValue.wheels,
    tires: CartValue.tires,
    tpms: tpms,
    nuts: CartValue.nuts,
    totalTires: CartValue.totalTires,
    totalItems: CartValue.totalItems,
    discount: CartValue.discount,
    discountAmount: CartValue.discountAmount,
  };

  //Calculate subtotal and discount
  let std = calculateSubTotal(newCartValue);
  //new cart values from subtotal and discount
  let discountAmount = std.discount;
  let subTotal = std.subtotal;
  //calculate ttt
  let tpstvqt = calculateTotal(subTotal - discountAmount);
  //new values from ttt
  let tps = tpstvqt.tps;
  let tvq = tpstvqt.tvq;
  let total = tpstvqt.total;

  //new cart value after $$$
  newCartValue = {
    wheels: CartValue.wheels,
    tires: CartValue.tires,
    tpms: tpms,
    nuts: CartValue.nuts,
    totalTires: CartValue.totalTires,
    totalItems: CartValue.totalItems,
    discount: CartValue.discount,
    discountAmount: discountAmount,
    subTotal: subTotal,
    tps: tps,
    tvq: tvq,
    total: total,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

//Change nuts qty on cart
export function changeQtyNuts(CartValue, newTotal) {
  //new cart values
  let nuts = { qty: newTotal };

  let newCartValue = {
    wheels: CartValue.wheels,
    tires: CartValue.tires,
    tpms: CartValue.tpms,
    nuts: nuts,
    totalTires: CartValue.totalTires,
    totalItems: CartValue.totalItems,
    discount: CartValue.discount,
    discountAmount: CartValue.discountAmount,
  };

  //Calculate subtotal and discount
  let std = calculateSubTotal(newCartValue);
  //new cart values from subtotal and discount
  let discountAmount = std.discount;
  let subTotal = std.subtotal;
  //calculate ttt
  let tpstvqt = calculateTotal(subTotal - discountAmount);
  //new values from ttt
  let tps = tpstvqt.tps;
  let tvq = tpstvqt.tvq;
  let total = tpstvqt.total;

  //new cart value after $$$
  newCartValue = {
    wheels: CartValue.wheels,
    tires: CartValue.tires,
    tpms: CartValue.tpms,
    nuts: nuts,
    totalTires: CartValue.totalTires,
    totalItems: CartValue.totalItems,
    discount: CartValue.discount,
    discountAmount: discountAmount,
    subTotal: subTotal,
    tps: tps,
    tvq: tvq,
    total: total,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

//Change discount
export function changePromoCode(CartValue, PromoCode) {
  PromoCode = PromoCode.toUpperCase();
  let discount = 0;
  if (PromoCode === "RABAIS8") {
    //new cart values
    discount = 0.08;
  }
  if (PromoCode === "CLUB12") {
    //new cart values
    discount = 0.12;
  }
  if (PromoCode === "MASTER15") {
    //new cart values
    discount = 0.15;
  }

  let newCartValue = {
    wheels: CartValue.wheels,
    tires: CartValue.tires,
    tpms: CartValue.tpms,
    nuts: CartValue.nuts,
    totalTires: CartValue.totalTires,
    totalItems: CartValue.totalItems,
    discount: discount,
    discountAmount: CartValue.discountAmount,
  };

  //Calculate subtotal and discount
  let std = calculateSubTotal(newCartValue);
  //new cart values from subtotal and discount
  let discountAmount = std.discount;
  let subTotal = std.subtotal;
  //calculate ttt
  let tpstvqt = calculateTotal(subTotal - discountAmount);
  //new values from ttt
  let tps = tpstvqt.tps;
  let tvq = tpstvqt.tvq;
  let total = tpstvqt.total;

  //new cart value after $$$
  newCartValue = {
    wheels: CartValue.wheels,
    tires: CartValue.tires,
    tpms: CartValue.tpms,
    nuts: CartValue.nuts,
    totalTires: CartValue.totalTires,
    totalItems: CartValue.totalItems,
    discount: discount,
    discountAmount: discountAmount,
    subTotal: subTotal,
    tps: tps,
    tvq: tvq,
    total: total,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

//Apply discount
export function applyDiscount(CartValue, AmountOfDiscount) {
  let newCartValue = {
    wheels: CartValue.wheels,
    tires: CartValue.tires,
    tpms: CartValue.tpms,
    nuts: CartValue.nuts,
    totalTires: CartValue.totalTires,
    totalItems: CartValue.totalItems,
    discount: CartValue.discount,
    discountAmount: AmountOfDiscount,
  };

  //Update local storage
  updateLocalStorageCart(JSON.stringify(newCartValue));

  return newCartValue;
}

/*------------------------*/
//round 2 decimals perfect
function roundNumber(numberToRound) {
  let res = Math.round((numberToRound + Number.EPSILON) * 100) / 100;
  return res;
}

//Calculate tire tax
function calcuateTireTax(CartValue) {
  let res = CartValue.totalTires * 3;
  return res;
}

//Calcualte Sub-Total without discount
//Returns : sub-total , discount
function calculateSubTotal(CartValue) {
  //calculate total $$$ tires tax
  let ttt = calcuateTireTax(CartValue);

  //calculate total $$$ wheels
  let tw = 0;
  let discount = 0;
  CartValue.wheels.map((item) => {
    if (item.sku !== undefined) {
      //calculate price of wheels
      tw += item.price * item.total;
      //if is a wheel pros product
      // if (item.supplier === "Wheel Pros") {
      //   discount += item.price * item.total * CartValue.discount;
      // }
      discount += item.price * item.total * CartValue.discount;
    }
  });

  //calculate total $$$ tires
  let tt = 0;
  CartValue.tires.map((item) => {
    if (item.sku !== undefined) {
      tt += item.price * item.total;
    }
  });

  //calculate total $$$ tpms
  let ttpms = CartValue.tpms.qty * 49.95;

  //calculate total $$$ nuts
  let tn = CartValue.nuts.qty * 49.95;

  //addition subtotal wjhitout discount
  let subtotal = roundNumber(ttt + tw + tt + ttpms + tn);

  discount = roundNumber(discount);

  return { subtotal: subtotal, discount: discount };
}

//Calculate TPS
function calculateTPS(givenSubTotal) {
  let res = roundNumber(givenSubTotal * 0.05);
  return res;
}

//Calculate TVQ
function calculateTVQ(givenSubTotal) {
  let res = roundNumber(givenSubTotal * 0.09975);
  return res;
}

//Calcualte Total
function calculateTotal(givenSubTotal) {
  let tps = calculateTPS(givenSubTotal);
  let tvq = calculateTVQ(givenSubTotal);
  let total = roundNumber(givenSubTotal + tps + tvq);

  return { tps: tps, tvq: tvq, total: total };
}
