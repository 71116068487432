const setSelectedVehicle = (userObj) => {
  return {
    type: "SET_VEHICLE",
    payload: userObj,
  };
};

export default {
  setSelectedVehicle,
};
