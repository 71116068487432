import React, { useState, useEffect } from "react";
import { changeQtyTire } from "../cartFunctions";
import * as StyledComponents from "./tireCardCart.module.scss";
import { removeTire } from "../cartFunctions";
import trash from "../../../icons/trash.svg";

//redux
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";

// markup
export const TireCartItem = ({
  image,
  brand,
  model,
  price,
  width,
  aspect,
  diam,
  total,
  sku,
}) => {
  //redux
  const NewCartContent = useSelector((state) => state.newCart);
  const dispatch = useDispatch();

  const [Count, setCount] = useState(total);

  //var to change the currency formats
  var formatter = new Intl.NumberFormat("fr-CA", {
    style: "currency",
    currency: "CAD",
  });

  //get the total on each update
  //only re renders if total changes
  useEffect(() => {
    let onOpenTotal = total;
    setCount(onOpenTotal);
  }, [total]);

  function changeCount(params) {
    let newCount = Count;
    if (params === "-" && Count !== 1) {
      newCount--;
      setCount(newCount);
      //change cart
      let newCart = changeQtyTire(NewCartContent, sku, newCount);
      dispatch(allActions.cart.setNewCart(newCart));
    }
    if (params === "+") {
      newCount++;
      setCount(newCount);
      //change cart
      let newCart = changeQtyTire(NewCartContent, sku, newCount);
      dispatch(allActions.cart.setNewCart(newCart));
    }
  }

  //removes the object by sku
  function remove() {
    let newCart = removeTire(NewCartContent, sku, total);
    dispatch(allActions.cart.setNewCart(newCart));
  }

  return (
    <div className={StyledComponents.item}>
      <button onClick={() => remove()} className={StyledComponents.xremove}>
        <img src={trash} alt="supprimer" />
      </button>
      <div className={StyledComponents.image}>
        <img
          src={image}
          alt={brand + " " + model + " " + width + "/" + aspect + "R" + diam}
        />
      </div>
      <div className={StyledComponents.desc}>
        <h3>{brand}</h3>
        <h5>{model}</h5>
        <p>{width + "/" + aspect + "R" + diam}</p>
        <div>
          <button onClick={() => changeCount("-")}>
            <h6>-</h6>
          </button>
          <h6 className={StyledComponents.count}>{Count}</h6>
          <button onClick={() => changeCount("+")}>
            <h6>+</h6>
          </button>
        </div>
        <h4>{formatter.format(price)}</h4>
      </div>
    </div>
  );
};
