import formActions from "./formActions";
import colapseCartItems from "./colapseCartItems";
import selectVehicle from "./selectVehicle";
import displayShippingForm from "./displayShippingForm";
import cart from "./cart";
import modifCartBtn from "./modifCartBtn";
import setDisplayStates from "./displayStates";

const allActions = {
  formActions,
  colapseCartItems,
  selectVehicle,
  displayShippingForm,
  cart,
  modifCartBtn,
  setDisplayStates,
};

export default allActions;
