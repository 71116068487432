const setDisplayStates = (state = {}, action) => {
  switch (action.type) {
    case "SET_DISPLAY_STATES":
      return {
        ...state,
        cartItems: action.payload.cartItems,
        changeCartBtn: action.payload.changeCartBtn,
        shippingFormBlock: action.payload.shippingFormBlock,
        insertAdressbtn: action.payload.insertAdressbtn,
        shippingForm: action.payload.shippingForm,
        changeShippingBtn: action.payload.changeShippingBtn,
        cc: action.payload.cc,
        vehicleSelected: action.payload.vehicleSelected,
      };
    default:
      if (state.totalItems === undefined) {
        //console.log("SET_DISPLAY_STATES UNDEFINED");
        return {
          ...state,
          cartItems: { display: "inline" },
          changeCartBtn: { display: "none" },
          shippingFormBlock: { display: "none" },
          insertAdressbtn: { display: "none" },
          shippingForm: { display: "none" },
          changeShippingBtn: { display: "none" },
          cc: { display: "none" },
          vehicleSelected: false,
        };
      } else {
        return {
          ...state,
        };
      }
  }
};

export default setDisplayStates;
