const setNewCart = (userObj) => {
  return {
    type: "SET_NEW_CART",
    payload: userObj,
  };
};

export default {
  setNewCart,
};
