const openMCB = (userObj) => {
  return {
    type: "OPEN_MCB",
    payload: userObj,
  };
};

const closeMCB = () => {
  return {
    type: "CLOSE_MCB",
  };
};

export default {
  openMCB,
  closeMCB,
};
