const openCartItems = () => {
  return {
    type: "OPEN",
  };
};

const closeCartItems = () => {
  return {
    type: "CLOSE",
  };
};

export default {
  openCartItems,
  closeCartItems,
};
