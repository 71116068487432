const closeForm = (state = {}, action) => {
  switch (action.type) {
    case "OPEN":
      return {
        ...state,
        display: "inline",
      };
    case "CLOSE":
      return {
        ...state,
        display: "none",
      };
    default:
      return state;
  }
};

export default closeForm;
