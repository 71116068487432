import React, { useState, useEffect } from "react";
import * as StyledComponents from "./cartItem.module.scss";
import { changeQtyWheel } from "../cartFunctions";
import trash from "../../../icons/trash.svg";
import { removeWheel } from "../cartFunctions";

//redux
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";

// markup
const CartItem = ({
  image,
  brand,
  name,
  stylenum,
  price,
  diam,
  width,
  offset,
  color,
  finish,
  total,
  sku,
  pcd1,
  pcd2,
}) => {
  //redux
  const NewCartContent = useSelector((state) => state.newCart);
  const dispatch = useDispatch();

  const [Count, setCount] = useState(total);

  //var to change the currency formats
  var formatter = new Intl.NumberFormat("fr-CA", {
    style: "currency",
    currency: "CAD",
  });

  //get the total on each update
  //only re renders if total changes
  useEffect(() => {
    let onOpenTotal = total;
    setCount(onOpenTotal);
  }, [total]);

  function changeCount(params) {
    let newCount = Count;
    if (params === "-" && Count !== 1) {
      newCount--;
      setCount(newCount);
      //change cart
      let newCart = changeQtyWheel(NewCartContent, sku, newCount);
      dispatch(allActions.cart.setNewCart(newCart));
    }
    if (params === "+") {
      newCount++;
      setCount(newCount);
      //change cart
      let newCart = changeQtyWheel(NewCartContent, sku, newCount);
      dispatch(allActions.cart.setNewCart(newCart));
    }
  }

  //removes the object by sku
  function remove() {
    let newCart = removeWheel(NewCartContent, sku, total);
    dispatch(allActions.cart.setNewCart(newCart));
  }

  //returns pcd2 if exists
  function pcd2Disp() {
    if (pcd2 === null || pcd2 === "") {
      return "";
    } else {
      return " | " + pcd2;
    }
  }

  var xoff = "";
  if (offset > 0) {
    xoff = "+";
  }
  return (
    <div className={StyledComponents.item}>
      <button onClick={() => remove()} className={StyledComponents.xremove}>
        <img src={trash} alt="supprimer" />
      </button>
      <div className={StyledComponents.image}>
        <img
          src={image}
          alt={
            brand +
            " " +
            name +
            " " +
            diam +
            "x" +
            width +
            " " +
            color +
            " " +
            finish
          }
        />
      </div>
      <div className={StyledComponents.desc}>
        <h3>{brand}</h3>
        <h5>{name + " " + stylenum}</h5>
        <p>
          {diam +
            "x" +
            width +
            " | " +
            xoff +
            offset +
            " | " +
            pcd1 +
            pcd2Disp()}
        </p>
        <div>
          <button onClick={() => changeCount("-")}>
            <h6>-</h6>
          </button>
          <h6 className={StyledComponents.count}>{Count}</h6>
          <button onClick={() => changeCount("+")}>
            <h6>+</h6>
          </button>
        </div>
        <h4>{formatter.format(price)}</h4>
      </div>
    </div>
  );
};

export default CartItem;
