import React from "react";
import * as StyledComponents from "./promocode.module.scss";

export const PromoCodeNavBar = () => {
  return (
    <div className={StyledComponents.promocode}>
      <p>
        12% de rabais sur tous nos mags <br /> Promo Code: CLUB12
      </p>
    </div>
  );
};
