import * as React from "react";
import TextScroll from "./textScroll/textscroll";
import PriceDelivery from "./priceDelivery/pricedelivery";
import Container from "../container/container";
import { Link } from "gatsby";

import * as StyledComponents from "./footer.module.scss";

// markup
const Footer = () => {
  return (
    <footer className={StyledComponents.footer}>
      <PriceDelivery />
      <TextScroll />
      <Container>
        <div className={StyledComponents.topfooter}>
          <div>
            <h2>
              Livraison rapide <br /> et gratuite
            </h2>
          </div>
          <div>
            <a href="https://www.facebook.com/CanadaOffroad">
              <h5>Facebook</h5>
            </a>
            {/* <Link to="/">
              <h5>Instagram</h5>
            </Link> */}
          </div>
          <div>
            <Link to="/">
              <h5>Accueil</h5>
            </Link>
            <Link to="/tires">
              <h5>Pneus</h5>
            </Link>

            <Link to="/wheels">
              <h5>Jantes / Mags</h5>
            </Link>
          </div>
          <div>
            <Link to="/contact">
              <h5>Contact</h5>
            </Link>
          </div>
          <div>
            {/* <Link to="/">
              <h5>Termes et conditions</h5>
            </Link> */}
            <Link to="/return">
              <h5>Annulations et retours</h5>
            </Link>
          </div>
        </div>
        <div className={StyledComponents.contact}>
          <div>
            <h5>Entrez en contact</h5>
            <a
              href="mailto:info@canadaoffroad.com"
              className={StyledComponents.email}
            >
              <h3>info@canadaoffroad.com</h3>
            </a>
          </div>
          {/* <div>
            <form>
              <h5>Accédez à nos promotions</h5>
              <input
                class="uk-input uk-width-1-2"
                type="text"
                placeholder="email@exemple.com"
              />
              <button class="uk-button">Envoyer</button>
            </form>
          </div>*/}
        </div>
        <div className={StyledComponents.brakeline}></div>
        <div className={StyledComponents.trademark}>
          <h2>
            CANADA OFFROAD <div>©2022</div>
          </h2>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
