import React from "react";
import * as StyledComponents from "./itemsInCart.module.scss";

//redux
import { useSelector } from "react-redux";

// markup
const ItemsInCart = () => {
  //redux
  const NewCartContent = useSelector((state) => state.newCart);

  return (
    <div className={StyledComponents.numberDisplay}>
      <p>{NewCartContent.totalItems.qty}</p>
    </div>
  );
};

export default ItemsInCart;
