const selectedVehicle = (state = {}, action) => {
  switch (action.type) {
    case "SET_VEHICLE":
      return {
        ...state,
        vehicle: action.payload.vehicle,
      };
    default:
      if (state.vehicle === undefined) {
        return {
          ...state,
          vehicle: "",
        };
      } else {
        return {
          ...state,
        };
      }
  }
};

export default selectedVehicle;
