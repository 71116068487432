const openForm = (userObj) => {
  return {
    type: "OPEN",
    payload: userObj,
  };
};

const closeForm = () => {
  return {
    type: "CLOSE",
  };
};

export default {
  openForm,
  closeForm,
};
