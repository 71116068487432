import React, { useState } from "react";
import { changePromoCode } from "../cartFunctions";
import * as StyledComponents from "./promoCodes.module.scss";

import { getMVehicle } from "../cartFunctions";
import { displayShippingBlock } from "../../setDisplayStates/functions";

//redux
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";

// markup
export const PromoCodes = () => {
  //redux
  const NewCartContent = useSelector((state) => state.newCart);
  const dispatch = useDispatch();
  //Cart in use Context
  const [PromoCode, setPromoCode] = useState("");

  //handle the form sublit
  const handlePromoCode = (e) => {
    let xcode = PromoCode.replace(/ /g, "");
    e.preventDefault();
    // perform operations and add to LocalStorage
    let newCart = changePromoCode(NewCartContent, xcode);
    //add promo code to redux cart
    dispatch(allActions.cart.setNewCart(newCart));

    //change display States
    let lssv = getMVehicle();
    //if vehicle is not undefined
    if (lssv !== undefined) {
      //if vehicle is not empty
      if (lssv.vehicle !== "") {
        //set selected vehicle on redux state
        dispatch(allActions.selectVehicle.setSelectedVehicle(lssv));
        //display shipping block
        let newDisplayStates = displayShippingBlock();
        dispatch(
          allActions.setDisplayStates.setDisplayStates(newDisplayStates)
        );
      }
    }
  };

  return (
    <>
      <form
        className={StyledComponents.form}
        onSubmit={(event) => {
          handlePromoCode(event);
        }}
      >
        <input
          type="text"
          placeholder="Promo code"
          value={PromoCode}
          onChange={(e) => setPromoCode(e.target.value)}
        />
        <button type="submit">Appliquer</button>
      </form>
    </>
  );
};
