const newCart = (state = {}, action) => {
  switch (action.type) {
    case "SET_NEW_CART":
      return {
        ...state,
        wheels: action.payload.wheels,
        tires: action.payload.tires,
        tpms: action.payload.tpms,
        nuts: action.payload.nuts,
        totalTires: action.payload.totalTires,
        totalItems: action.payload.totalItems,
        discount: action.payload.discount,
        discountAmount: action.payload.discountAmount,
        subTotal: action.payload.subTotal,
        tps: action.payload.tps,
        tvq: action.payload.tvq,
        total: action.payload.total,
      };
    default:
      if (state.totalItems === undefined) {
        return {
          ...state,
          wheels: [{}],
          tires: [{}],
          tpms: { qty: 0 },
          nuts: { qty: 0 },
          totalItems: { qty: 0 },
          totalTires: 0,
          discount: 0,
          discountAmount: 0,
          subTotal: 0,
          tps: 0,
          tvq: 0,
          total: 0,
        };
      } else {
        return {
          ...state,
        };
      }
  }
};

export default newCart;
