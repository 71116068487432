import React from "react";
import { Helmet } from "react-helmet";
import LCartGlobalContext from "../../context/cartGlobalContext";
import { NavBar } from "../navbar/navbar";
import Footer from "../footer/footer";
import * as StyledComponents from "./layout.module.scss";

//React-redux
import { createStore } from "redux";
import rootReducer from "../redux/reducers";
import { Provider } from "react-redux";

const store = createStore(
  rootReducer
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

//Markup
export const PageLayout = ({ children }) => {
  return (
    <LCartGlobalContext>
      <Provider store={store}>
        <Helmet defer={false}>
          <title>
            CANDA OFFROAD | Trouvez des pneus et jantes pour votre véhicule
          </title>
          <meta
            name="facebook-domain-verification"
            content="cj9ib4tv3kddiyfiaw6nelbhvviiw7"
          />
          <meta
            name="description"
            content="Magasin en ligne de pneus et jantes à bas prix"
          />
          <meta
            name="og:title"
            content="CANDA OFFROAD | Trouvez des pneus et jantes pour votre véhicule"
          />
        </Helmet>
        <NavBar />
        <main className={StyledComponents.main}>{children}</main>
        <Footer />
      </Provider>
    </LCartGlobalContext>
  );
};
