import React, { useState, useEffect } from "react";
import { removeSensors } from "../cartFunctions";
import { changeQtyTPMS } from "../cartFunctions";
import trash from "../../../icons/trash.svg";
import * as StyledComponents from "./sensorCartCard.module.scss";

//redux
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";

// markup
export const SensorCartCard = ({ numberOfSensors }) => {
  //redux
  const NewCartContent = useSelector((state) => state.newCart);
  const dispatch = useDispatch();

  const SensorProduct = {
    description: "1x max sensors rubber",
    image:
      "https://cdn.bfldr.com/T4M9XMBZ/at/2675spwwp67tg72snzgpg34r/GEN2-UP-RUBBER.png?auto=webp&format=png",
    name: "MAX SENSOR",
    price: 49.95,
    sku: "MX002R",
  };
  const [Count, setCount] = useState(numberOfSensors);
  //var to change the currency formats
  var formatter = new Intl.NumberFormat("fr-CA", {
    style: "currency",
    currency: "CAD",
  });

  //get the numberOfSensors on each update
  //only re renders if numberOfSensors changes
  useEffect(() => {
    let onOpenTotal = numberOfSensors;
    setCount(onOpenTotal);
  }, [numberOfSensors]);

  function changeCount(params) {
    let newCount = Count;
    if (params === "-" && Count !== 1) {
      newCount--;
      setCount(newCount);
      //change the cart
      let newCart = changeQtyTPMS(NewCartContent, newCount);
      dispatch(allActions.cart.setNewCart(newCart));
    }
    if (params === "+") {
      newCount++;
      setCount(newCount);
      //change the cart
      let newCart = changeQtyTPMS(NewCartContent, newCount);
      dispatch(allActions.cart.setNewCart(newCart));
    }
  }

  //removes all sensors
  function remove() {
    let newCart = removeSensors(NewCartContent);
    dispatch(allActions.cart.setNewCart(newCart));
  }

  return (
    <div className={StyledComponents.item}>
      <button onClick={() => remove()} className={StyledComponents.xremove}>
        <img src={trash} alt="supprimer" />
      </button>
      <div className={StyledComponents.image}>
        <img src={SensorProduct.image} alt={SensorProduct.description} />
      </div>
      <div className={StyledComponents.desc}>
        <h3>{SensorProduct.name}</h3>
        <p>{SensorProduct.description}</p>
        <div>
          <button onClick={() => changeCount("-")}>
            <h6>-</h6>
          </button>
          <h6 className={StyledComponents.count}>{Count}</h6>
          <button onClick={() => changeCount("+")}>
            <h6>+</h6>
          </button>
        </div>
        <h4>{formatter.format(SensorProduct.price)}</h4>
      </div>
    </div>
  );
};
