import React, { useState, useEffect } from "react";
import { removeNuts } from "../cartFunctions";
import { changeQtyNuts } from "../cartFunctions";
import trash from "../../../icons/trash.svg";
import * as StyledComponents from "./nutsCartCard.module.scss";

//redux
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";

// markup
export const NutsCartCard = ({ numberOfNuts }) => {
  //redux
  const NewCartContent = useSelector((state) => state.newCart);
  const dispatch = useDispatch();

  const NutsProduct = {
    description: "Nuts, tuner key, standard valve and centering rings",
    image:
      "https://cdn.bfldr.com/T4M9XMBZ/at/j9v38843nt8z6ch84hn4k3hn/5744LK8-BLK-INSTALLKIT-06.png?auto=webp&format=png",
    name: "TUNER KIT",
    price: 49.95,
    sku: "MX002R",
  };
  const [Count, setCount] = useState(numberOfNuts);
  //var to change the currency formats
  var formatter = new Intl.NumberFormat("fr-CA", {
    style: "currency",
    currency: "CAD",
  });

  //get the numberOfNuts on each update
  //only re renders if numberOfNuts changes
  useEffect(() => {
    let onOpenTotal = numberOfNuts;
    setCount(onOpenTotal);
  }, [numberOfNuts]);

  function changeCount(params) {
    let newCount = Count;
    if (params === "-" && Count !== 1) {
      newCount--;
      setCount(newCount);
      //change the cart
      let newCart = changeQtyNuts(NewCartContent, newCount);
      dispatch(allActions.cart.setNewCart(newCart));
    }
    if (params === "+") {
      newCount++;
      setCount(newCount);
      //change the cart
      let newCart = changeQtyNuts(NewCartContent, newCount);
      dispatch(allActions.cart.setNewCart(newCart));
    }
  }

  //removes all nuts
  function remove() {
    let newCart = removeNuts(NewCartContent);
    dispatch(allActions.cart.setNewCart(newCart));
  }

  return (
    <div className={StyledComponents.item}>
      <button onClick={() => remove()} className={StyledComponents.xremove}>
        <img src={trash} alt="supprimer" />
      </button>
      <div className={StyledComponents.image}>
        <img src={NutsProduct.image} alt={NutsProduct.description} />
      </div>
      <div className={StyledComponents.desc}>
        <h3>{NutsProduct.name}</h3>
        <p>{NutsProduct.description}</p>
        <div>
          <button onClick={() => changeCount("-")}>
            <h6>-</h6>
          </button>
          <h6 className={StyledComponents.count}>{Count}</h6>
          <button onClick={() => changeCount("+")}>
            <h6>+</h6>
          </button>
        </div>
        <h4>{formatter.format(NutsProduct.price)}</h4>
      </div>
    </div>
  );
};
