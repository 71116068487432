import * as React from "react";

import * as StyledComponents from "./textscroll.module.scss";

// markup
const TextScroll = () => {
  return (
    <div className={StyledComponents.textContainer}>
      <div className={StyledComponents.text1}>
        <h1>CANADA OFFROAD ❖&nbsp;</h1>
      </div>
      <div className={StyledComponents.text2}>
        <h1>CANADA OFFROAD ❖&nbsp;</h1>
      </div>
    </div>
  );
};
export default TextScroll;
