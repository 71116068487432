// extracted by mini-css-extract-plugin
export var icon = "cart-module--icon--ZTcG-";
export var cartContainer = "cart-module--cartContainer--bozv5";
export var exit = "cart-module--exit--xNIUW";
export var cart = "cart-module--cart--gsWNo";
export var header = "cart-module--header--YKgZP";
export var close = "cart-module--close--fNV1d";
export var selectCar = "cart-module--selectCar--v2YTK";
export var chosen = "cart-module--chosen--3eLes";
export var selectedCar = "cart-module--selectedCar--WCeDy";
export var empty = "cart-module--empty--bRgCb";
export var half = "cart-module--half--vXge+";
export var changeCartBtn = "cart-module--changeCartBtn--MaJ-W";
export var ShippingForm = "cart-module--ShippingForm--kTj+I";
export var footer = "cart-module--footer--CSEj-";
export var rabais = "cart-module--rabais--iXUb2";
export var freeShipping = "cart-module--freeShipping--E9PBJ";