import * as React from "react";
import Container from "../../container/container";
import * as StyledComponets from "./pricedelivery.module.scss";
import tag from "../../../images/discount-tag.webp";
import deliverybox from "../../../images/delivery-box.webp";

// markup
const PriceDelivery = () => {
  return (
    <Container>
      <section className={StyledComponets.container}>
        <div className={StyledComponets.left}>
          <img src={tag} alt="discount tag" />
          <div>
            <h1>Meilleur prix garanti</h1>
            <h2>Nous battons tous les prix de la compétition</h2>
          </div>
        </div>
        <div className={StyledComponets.right}>
          <img src={deliverybox} alt="delivery box" />
          <div>
            <h1>Livraison rapide et gratuite</h1>
            <h2>À l'achat de 400&nbsp;$ et plus avant taxes</h2>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default PriceDelivery;
